import React from "react";

const HeaderUserIcon = (props) => {
  return (
    <svg
      {...props}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 2C8.76801 2 2.5 8.26801 2.5 16C2.5 23.732 8.76801 30 16.5 30C24.232 30 30.5 23.732 30.5 16C30.4916 8.27148 24.2285 2.00836 16.5 2ZM16.5 4.91667C20.7997 4.90621 24.7159 7.38807 26.542 11.2807C28.3681 15.1733 27.7732 19.7714 25.0167 23.0712C24.8491 23.2722 24.5682 23.3371 24.3295 23.2298C23.3028 22.7632 22.0603 22.3082 20.6428 21.7867L19.7748 21.467C19.5647 21.3143 19.4277 21.0809 19.3968 20.823C19.2255 20.2214 19.3042 19.5761 19.615 19.0333C20.861 17.6613 21.6333 16.1867 21.6333 13.0833C21.7584 11.6591 21.269 10.2492 20.2884 9.20879C19.3077 8.16837 17.9292 7.59655 16.5 7.63733C15.0708 7.59655 13.6923 8.16837 12.7116 9.20879C11.731 10.2492 11.2416 11.6591 11.3667 13.0833C11.3667 16.1902 12.139 17.6648 13.385 19.0333C13.6975 19.575 13.7782 20.2198 13.609 20.8218C13.5781 21.0797 13.4411 21.3131 13.231 21.4658L12.363 21.7855C10.9455 22.307 9.703 22.7655 8.67633 23.2287C8.43762 23.3359 8.1567 23.271 7.98917 23.07C5.23403 19.771 4.63919 15.1748 6.46373 11.2831C8.28827 7.39141 12.2019 4.90886 16.5 4.91667Z"
        // fill="#2C4FF8"
      />
    </svg>
  );
};

export default HeaderUserIcon;
