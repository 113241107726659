import React from "react";

const FacebookIcon = (props) => {
  return (
    <svg
      {...props}
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
   
        d="M8.33755 0C8.43159 0 8.50994 0.03125 8.57263 0.09375C8.63532 0.15625 8.66667 0.234375 8.66667 0.328125V2.76562C8.66667 2.85938 8.63532 2.9375 8.57263 3C8.50994 3.0625 8.43159 3.09375 8.33755 3.09375H6.94274C6.47257 3.09375 6.16174 3.1875 6.01025 3.375C5.85875 3.5625 5.78823 3.84375 5.79867 4.21875V5.75H8.24352C8.33755 5.75 8.41591 5.78125 8.4786 5.84375C8.54129 5.90625 8.57263 5.98438 8.57263 6.07812V8.70312C8.57263 8.79688 8.54129 8.875 8.4786 8.9375C8.41591 9 8.33755 9.03125 8.24352 9.03125H5.79867V15.6719C5.79867 15.7656 5.76733 15.8438 5.70464 15.9062C5.64195 15.9688 5.56359 16 5.46956 16H2.74262C2.64858 16 2.57022 15.9688 2.50753 15.9062C2.44485 15.8438 2.4135 15.7656 2.4135 15.6719V9.03125H0.313442C0.229858 9.03125 0.156721 9 0.0940325 8.9375C0.0313442 8.875 0 8.79688 0 8.70312V6.07812C0 5.98438 0.0313442 5.90625 0.0940325 5.84375C0.156721 5.78125 0.229858 5.75 0.313442 5.75H2.4135V3.9375C2.42395 2.72917 2.77135 1.77344 3.4557 1.07031C4.14004 0.367188 5.07253 0.0104219 6.25316 0H7.30319C7.90918 0 8.25397 0 8.33755 0Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookIcon;
