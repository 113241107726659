import React from "react";

const InstagramIcon = (props) => {
  return (
    <svg
    {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0416 5.95834H16.0508M6.41659 1.83334H15.5833C18.1146 1.83334 20.1666 3.88537 20.1666 6.41668V15.5833C20.1666 18.1146 18.1146 20.1667 15.5833 20.1667H6.41659C3.88528 20.1667 1.83325 18.1146 1.83325 15.5833V6.41668C1.83325 3.88537 3.88528 1.83334 6.41659 1.83334ZM14.6666 10.4225C14.7797 11.1854 14.6494 11.9645 14.2942 12.6491C13.939 13.3337 13.377 13.8888 12.6881 14.2355C11.9992 14.5823 11.2185 14.703 10.4571 14.5804C9.69562 14.4579 8.9922 14.0984 8.44685 13.5531C7.90151 13.0077 7.54201 12.3043 7.41948 11.5429C7.29695 10.7814 7.41764 10.0007 7.76438 9.31185C8.11112 8.62296 8.66626 8.06094 9.35082 7.70573C10.0354 7.35052 10.8145 7.22022 11.5774 7.33334C12.3556 7.44874 13.076 7.81135 13.6323 8.36762C14.1886 8.9239 14.5512 9.64433 14.6666 10.4225Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InstagramIcon;
