import React from "react";

const LocationIcon = (props) => {
  return (
    <svg
      {...props}
      width="18"
      height="28"
      viewBox="0 0 18 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        
        d="M9.00504 0.320007C4.13504 0.320007 0.165039 4.27001 0.165039 9.15001C0.165039 14.03 8.43503 27.29 8.43503 27.29C8.75503 27.81 9.27502 27.81 9.58502 27.29C9.58502 27.29 17.835 14.02 17.835 9.15001C17.835 4.28001 13.885 0.320007 9.00504 0.320007ZM9.00504 2.92001C11.775 2.92001 14.035 5.16001 14.035 7.93001C14.035 10.7 11.785 12.96 9.00504 12.96C6.22504 12.96 3.98502 10.71 3.98502 7.93001C3.98502 5.15001 6.22504 2.92001 9.00504 2.92001Z"
    
      />
    </svg>
  );
};

export default LocationIcon;
