import React from "react";

const PricesIcon = (props) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        
        d="M24.2549 0.325012H3.73494L0.314941 17.425H27.6749L24.2549 0.325012ZM14.6849 10.935C14.6849 10.595 14.6049 10.315 14.4349 10.095C14.2749 9.86501 13.9949 9.66501 13.5949 9.47501C12.9749 9.23501 12.5049 8.93501 12.1849 8.60501C11.8649 8.25501 11.7149 7.77501 11.7149 7.17501C11.7149 6.57501 11.8749 6.12501 12.1749 5.75501C12.4749 5.39501 12.8749 5.17501 13.4049 5.10501V3.99501H14.0949V5.12501C14.6049 5.20501 15.0049 5.45501 15.2949 5.87501C15.5849 6.28501 15.7349 6.84502 15.7349 7.53502H14.6549C14.6549 7.06502 14.5749 6.67501 14.4049 6.38501C14.2249 6.08501 13.9849 5.94501 13.6949 5.94501C13.3949 5.94501 13.1549 6.05502 13.0049 6.28502C12.8449 6.51502 12.7649 6.79501 12.7649 7.16501C12.7649 7.51501 12.8449 7.78501 13.0049 7.99501C13.1649 8.20501 13.4549 8.40501 13.8749 8.61501C14.5149 8.87501 14.9749 9.16501 15.2849 9.51501C15.5849 9.85501 15.7349 10.325 15.7349 10.925C15.7349 11.525 15.5849 11.975 15.2649 12.335C14.9649 12.695 14.5349 12.905 13.9949 12.965V13.985H13.3049V12.965C12.7649 12.915 12.3249 12.695 11.9749 12.305C11.6349 11.915 11.4549 11.345 11.4549 10.605H12.5149C12.5149 11.155 12.6249 11.555 12.8349 11.785C13.0549 12.035 13.3149 12.145 13.6149 12.145C13.9549 12.145 14.2149 12.035 14.3949 11.815C14.5749 11.575 14.6649 11.285 14.6649 10.925L14.6849 10.935ZM22.5549 15.715H5.45494C5.45494 14.175 4.44494 12.905 3.05494 12.475L4.48494 5.29501C4.78494 5.39501 5.10494 5.46501 5.45494 5.46501C7.33494 5.46501 8.86494 3.92501 8.86494 2.03502H19.1249C19.1249 3.92501 20.6549 5.46501 22.5449 5.46501C22.8849 5.46501 23.1949 5.39501 23.5049 5.29501L24.9349 12.475C23.5549 12.905 22.5449 14.175 22.5449 15.715H22.5549ZM0.324944 19.125H27.6849V20.835H0.324944V19.125ZM0.324944 22.555H27.6849V24.255H0.324944V22.555ZM0.324944 25.965H27.6849V27.675H0.324944V25.965Z"
        
      />
    </svg>
  );
};

export default PricesIcon;
