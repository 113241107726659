import React from "react";

const EarthIcon = (props) => {
  return (
    <svg
    {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90782 7.95071C6.85623 8.58989 6.83144 9.22514 6.83342 9.86275C6.83567 10.5881 6.87256 11.3148 6.94351 12.0521C5.41939 11.9308 3.84238 11.7123 2.12097 11.3937C2.04146 10.9411 2 10.4754 2 10C2 9.52462 2.04146 9.05893 2.12097 8.60633C3.82895 8.29018 5.39477 8.07264 6.90782 7.95071ZM7.917 7.88318C7.85907 8.54627 7.83138 9.20236 7.83341 9.85965C7.83573 10.6051 7.87629 11.3542 7.95506 12.1188C9.32309 12.19 10.6643 12.1814 12.0473 12.0955C12.1245 11.339 12.1643 10.5976 12.1666 9.85965C12.1686 9.21018 12.1416 8.56188 12.0851 7.90683C10.6758 7.81793 9.31066 7.80923 7.917 7.88318ZM11.9755 6.89839C10.6438 6.82014 9.34702 6.8121 8.02742 6.87634C8.2275 5.35611 8.58354 3.77893 9.10136 2.04991C9.39636 2.01693 9.6962 2 10 2C10.3038 2 10.6036 2.01693 10.8986 2.04991C11.419 3.7873 11.7759 5.37136 11.9755 6.89839ZM13.0948 7.98347C13.1446 8.6116 13.1685 9.23603 13.1666 9.86275C13.1644 10.5775 13.1285 11.2935 13.0596 12.0196C14.5721 11.8872 16.1521 11.6661 17.885 11.3591C17.9606 10.9174 18 10.4633 18 10C18 9.53671 17.9606 9.08262 17.885 8.64089C16.1655 8.33628 14.5966 8.11622 13.0948 7.98347ZM17.6278 7.58113C15.9761 7.29813 14.4553 7.09391 12.9926 6.97112C12.8083 5.46398 12.4775 3.91779 12 2.25203C14.67 2.93925 16.8006 4.97026 17.6278 7.58113ZM7.01124 6.93971C5.54048 7.05129 4.02404 7.25126 2.38458 7.54238C3.22049 4.95017 5.34319 2.93584 8.00002 2.25203C7.52579 3.90622 7.19631 5.44249 7.01124 6.93971ZM2.38458 12.4576C4.0426 12.752 5.57479 12.9532 7.06116 13.064C7.26281 14.5482 7.59714 16.0905 8.05944 17.763C5.37486 17.0941 3.22672 15.0691 2.38458 12.4576ZM11.9236 13.1046C10.6276 13.179 9.36402 13.1869 8.07951 13.1262C8.29537 14.6302 8.65311 16.2114 9.15252 17.9556C9.43101 17.985 9.71375 18 10 18C10.2863 18 10.569 17.985 10.8475 17.9556C11.3493 16.203 11.708 14.6151 11.9236 13.1046ZM11.9406 17.763C12.4061 16.0789 12.7418 14.5267 12.943 13.033C14.421 12.9107 15.9574 12.7051 17.6278 12.4189C16.7945 15.0491 14.6384 17.0908 11.9406 17.763Z"
        fill="#4D535A"
      />
    </svg>
  );
};

export default EarthIcon;
