import React from "react";

const MailIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.62142 9.51447C3.4112 9.30765 3.42576 8.96579 3.65281 8.77737L11.6801 2.11593C11.8657 1.96194 12.1353 1.96147 12.3214 2.11482L20.405 8.77575C20.6341 8.96455 20.6484 9.30905 20.4357 9.51596L15.6672 14.1539C15.4792 14.3368 15.181 14.3442 14.9841 14.1708L12.3327 11.8365C12.1429 11.6694 11.8576 11.6694 11.6678 11.8365L9.01839 14.169C8.82064 14.3431 8.52097 14.3349 8.33323 14.1501L3.62142 9.51447ZM21.9069 10.2616C21.85 10.2376 21.784 10.2504 21.7403 10.294L16.5735 15.4307C16.4924 15.5118 16.4924 15.6429 16.5735 15.724L20.7761 19.9022C20.9386 20.0581 21.0039 20.2891 20.9468 20.5064C20.8897 20.7237 20.7192 20.8934 20.5007 20.9504C20.3404 20.9922 20.1727 20.9685 20.0328 20.8893L12.7211 13.7567C12.3222 13.3687 11.6765 13.3687 11.2775 13.7567L3.9789 20.8781C3.74041 21.011 3.43353 20.9761 3.23138 20.7749C2.99041 20.5351 2.9872 20.1476 3.22418 19.9039L7.42674 15.7257C7.50789 15.6446 7.50789 15.5135 7.42674 15.4324L2.26 10.2956C2.21652 10.2516 2.15027 10.2388 2.09333 10.2633C2.03737 10.2865 2.00067 10.3406 2 10.4008V20.343C2 21.2581 2.7462 22 3.66669 22H20.3336C21.2541 22 22.0003 21.2581 22.0003 20.343V10.4008C22.0005 10.3399 21.9636 10.2849 21.9069 10.2616Z"
        // fill="white"
      />
    </svg>
  );
};

export default MailIcon;
