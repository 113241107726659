import React from "react";

const SafetyIcon = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="26"
      viewBox="0 0 20 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
       
        d="M18.4953 0.889893H1.50479C0.672316 0.894888 0.000100028 1.57116 0.000115013 2.40365V8.72411C-0.0239628 15.5556 3.73405 21.8395 9.76388 25.0505C9.83646 25.0896 9.9176 25.1101 10 25.1101C10.0827 25.1103 10.1641 25.0902 10.2372 25.0515C16.2661 21.8392 20.0235 15.5554 19.9999 8.72411V2.40365C19.9999 1.57116 19.3277 0.894888 18.4953 0.889893ZM15.9542 8.90777L8.78902 15.6692C8.39004 16.0472 7.76196 16.0365 7.37617 15.645L4.9814 13.227C4.72062 12.9723 4.61751 12.5967 4.71165 12.2445C4.80579 11.8923 5.08255 11.6183 5.43565 11.5277C5.78875 11.437 6.16329 11.5438 6.41544 11.8071L8.11287 13.5227L14.5665 7.44043C14.8287 7.19256 15.2031 7.10335 15.5488 7.20641C15.8945 7.30947 16.159 7.58914 16.2426 7.94008C16.3262 8.29101 16.2163 8.6599 15.9542 8.90777Z"
       
      />
    </svg>
  );
};

export default SafetyIcon;
