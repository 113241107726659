import React from "react";

const PhoneIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1553 14.6376C18.4002 13.8886 17.4532 13.8886 16.6981 14.6376C16.1225 15.2071 15.5469 15.7766 14.9836 16.3584C14.8289 16.5194 14.6989 16.5503 14.507 16.4451C14.1357 16.2408 13.7395 16.0799 13.3806 15.857C11.7156 14.8109 10.3168 13.4615 9.07887 11.9387C8.46611 11.1836 7.91525 10.3789 7.53769 9.46892C7.46341 9.28321 7.4696 9.1656 7.62434 9.01704C8.19996 8.45994 8.7632 7.89045 9.32645 7.32097C10.1125 6.52864 10.1125 5.60014 9.32026 4.80162C8.87461 4.34975 8.42278 3.89787 7.97095 3.45219C7.50674 2.98794 7.04872 2.52368 6.57832 2.05943C5.81701 1.31662 4.87621 1.31043 4.1149 2.05943C3.53928 2.62891 2.97604 3.21697 2.39423 3.77407C1.84956 4.29404 1.57103 4.92542 1.51532 5.66823C1.42867 6.8691 1.71958 8.00806 2.13427 9.11608C2.98842 11.4064 4.28202 13.4367 5.84796 15.3061C7.96476 17.8317 10.5024 19.8249 13.4672 21.2671C14.7979 21.9171 16.1844 22.4123 17.6884 22.4928C18.7221 22.5485 19.6257 22.2885 20.3437 21.4838C20.8389 20.9329 21.3897 20.4315 21.9158 19.9053C22.6895 19.1254 22.6957 18.1783 21.9282 17.4046C20.9998 16.476 20.0776 15.5599 19.1553 14.6376Z"
       
      />
    </svg>
  );
};

export default PhoneIcon;
